class Header {
  constructor() {
    this.header = document.querySelector('.js-header');
    this.navMobile = document.querySelector('.js-nav-mobile');
    this.navToggle = document.querySelector('.js-nav-toggle');
  }

  init() {
    const {
      header,
      navMobile,
      navToggle
    } = this;

    const navMobileLinks = navMobile.querySelectorAll('a');

    if (!header) {
      return;
    }

    window.addEventListener('scroll', () => {
      if (window.pageYOffset > window.innerHeight + 10) {
        header.classList.add('is-sticky');
      } else {
        header.classList.remove('is-sticky');
      }
    });

    if (navToggle) {
      navToggle.addEventListener('click', (e) => {
        e.preventDefault();

        header.classList.toggle('is-nav-open');
        navMobile.classList.toggle('is-open');
      });

      navMobileLinks.forEach((link) => {
        link.addEventListener('click', () => {
          header.classList.remove('is-nav-open');
          navMobile.classList.remove('is-open');
        });
      });
    }
  }
}

export default Header;
