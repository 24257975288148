import SmoothScrollLib from 'smooth-scroll';

class SmoothScroll {
  constructor() {
    this.scope = 'a[href*="#"]';

    this.options = {
      updateURL: false
    };
  }

  init() {
    const { scope, options } = this;

    new SmoothScrollLib(scope, options);
  }
}

export default SmoothScroll;
