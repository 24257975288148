import Carousel from './carousel';

class CarouselImages extends Carousel {
  constructor() {
    const scope = '.js-carousel-images';

    const options = {
      mode: 'gallery',
      controlsContainer: `${scope}-controls`
    };

    super(scope, options);
  }
}

export default CarouselImages;
