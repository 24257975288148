class Gallery {
  constructor() {
    this.gallery = document.querySelector('.js-gallery');
  }

  init() {
    const {
      gallery
    } = this;

    if (!gallery) {
      return;
    }

    const galleryItems = gallery.querySelectorAll('a');

    galleryItems.forEach((item) => {
      item.addEventListener('click', (e) => {
        e.preventDefault();

        galleryItems.forEach((item2) => {
          if (item === item2) {
            item.classList.toggle('is-open');
          } else {
            item2.classList.remove('is-open');}
        });
      });
    });
  }
}

export default Gallery;
