import Carousel from './carousel';

class CarouselImagesWContact extends Carousel {
  constructor() {
    const scope = '.js-carousel-images-w-contact';

    const options = {
      mode: 'carousel',
      items: 1,
      controlsContainer: `${scope}-controls`,
      gutter: 40,
      mouseDrag: false,
      responsive: {
        992: {
          items: 2
        },
        1200: {
          items: 3
        }
      }
    };

    super(scope, options);
  }
}

export default CarouselImagesWContact;
