import Carousel from './carousel';

class CarouselImagesWText extends Carousel {
  constructor() {
    const scope = '.js-carousel-images-w-text';

    const options = {
      mode: 'gallery',
      controlsContainer: `${scope}-controls`,
      animateIn: 'is-in',
      animateOut: 'is-out',
      animateNormal: 'is-normal',
      speed: 1000
    };

    super(scope, options);
  }

  init() {
    super.init();

    const { carousel } = this;

    if (!carousel) {
      return;
    }

    carousel.tns.events.on('indexChanged', () => {
      this.updateControlsPosition();
    });

    this.updateControlsPosition();

    window.addEventListener('resize', () => {
      this.updateControlsPosition();
    });
  }

  updateControlsPosition() {
    if (window.innerWidth >= 1200) {
      this.resetControlsPosition();
      return;
    }

    const { scope, carousel } = this;
    const activeItem = carousel.querySelector('.tns-slide-active');
    const controls = document.querySelectorAll(`${scope}-controls button`);

    if (activeItem) {
      let activeItemImage = activeItem.querySelector(`${scope}-item-image-mobile`);

      if (getComputedStyle(activeItemImage, null).display === 'none') {
        activeItemImage = activeItem.querySelector(`${scope}-item-image`);
      }

      if (activeItemImage) {
        if (activeItemImage.querySelector('img').complete) {
          controls.forEach((control) => {
            control.style.top = `${activeItemImage.offsetHeight - control.offsetHeight - 40}px`;
          });
        } else {
          activeItemImage.querySelector('img').addEventListener('load', () => {
            controls.forEach((control) => {
              control.style.top = `${activeItemImage.offsetHeight - control.offsetHeight - 40}px`;
            });
          });
        }
      }
    }
  }

  resetControlsPosition() {
    const { scope } = this;
    const controls = document.querySelectorAll(`${scope}-controls button`);

    controls.forEach((control) => {
      control.removeAttribute('style');
    });
  }
}

export default CarouselImagesWText;
