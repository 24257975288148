import 'unfetch/polyfill';
import Loader from './components/loader';
import Header from './components/header';
import Reveal from './components/reveal';
import SmoothScroll from './components/smooth-scroll';
import FormSubscribe from './components/form-subscribe';
import Video from './components/video';
import CarouselImages from './components/carousel-images';
import CarouselImagesWText from './components/carousel-images-w-text';
import CarouselImagesWContact from './components/carousel-images-w-contact';
import Gallery from './components/gallery';

class App {
  constructor() {
    this.header = new Header();
    this.reveal = new Reveal();
    this.loader = new Loader();
    this.smoothScroll = new SmoothScroll();
    this.formSubscribe = new FormSubscribe();
    this.video = new Video();
    this.carouselImages = new CarouselImages();
    this.carouselImagesWText = new CarouselImagesWText();
    this.carouselImagesWContact = new CarouselImagesWContact();
    this.gallery = new Gallery();
  }

  init() {
    this.header.init();
    this.reveal.init();
    this.loader.init();
    this.smoothScroll.init();
    this.formSubscribe.init();
    this.video.init();
    this.carouselImages.init();
    this.carouselImagesWText.init();
    this.carouselImagesWContact.init();
    this.gallery.init();
  }
}

const app = new App();
app.init();
