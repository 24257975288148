import { tns } from 'tiny-slider/src/tiny-slider';

class Carousel {
  constructor(scope, options = {}) {
    this.scope = scope;
    this.carousel = document.querySelector(scope);
    this.tns = null;

    const defaultOptions = {
      container: scope,
      items: 1,
      mouseDrag: true,
      loop: true,
      nav: false,
      controls: true,
      controlsPosition: 'bottom',
      controlsText: [
        '<svg width="16" height="14"><g stroke="#000" stroke-width="1.5" fill="none" fill-rule="evenodd"><path stroke-linecap="square" d="M15 6.783H3.06"/><path d="M9.373 12.779L2.061 7l7.312-5.779"/></g></svg>',
        '<svg width="16" height="14"><g stroke="#000" stroke-width="1.5" fill="none" fill-rule="evenodd"><path stroke-linecap="square" d="M1 7.217h11.94"/><path d="M6.627 1.221L13.939 7l-7.312 5.779"/></g></svg>'
      ],
      autoplayButtonOutput: false
    };

    this.options = { ...defaultOptions, ...options };
  }



  init() {
    const { carousel, options } = this;

    if (!carousel) {
      return;
    }

    this.tns = tns(options);
    carousel.tns = this.tns;
  }
}

export default Carousel;
