import serialize from 'form-serialize';

class FormSubscribe {
  constructor() {
    this.scope = '.js-form-subscribe';
    this.formWrapper = document.querySelector(this.scope);

    if (!this.formWrapper) {
      return;
    }

    this.form = this.formWrapper.querySelector('form');
    this.button = this.form.querySelector('button');
  }

  init() {
    const { formWrapper, form, button } = this;

    if (!form) {
      return;
    }

    form.addEventListener('submit', (e) => {
      e.preventDefault();

      const data = serialize(form, { hash: true });

      fetch(window.gzData.ajaxUrl, {
        method: 'post',
        body: `action=gz_form_subscribe&name=${data.name}&email=${data.email}`,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error();
          }

          return response.json();
        })
        .then(() => {
          formWrapper.classList.add('is-done', 'is-success');
          formWrapper.classList.remove('is-error');
          button.disabled = true;
        })
        .catch(() => {
          formWrapper.classList.add('is-done', 'is-error');
          formWrapper.classList.remove('is-success');
        });
    });
  }
}

export default FormSubscribe;
