// import Waypoint from 'waypoints';

class Reveal {
  constructor() {
    this.reveal = this.reveal.bind(this);
    this.revealOnIntersect = this.revealOnIntersect.bind(this);

    this.elements = document.querySelectorAll('.js-reveal');
    this.observer = 'IntersectionObserver' in window ? new IntersectionObserver(this.revealOnIntersect, {
      root: null,
      rootMargin: '0px'
    }) : null;
  }

  reveal(element) {
    element.classList.add('is-reveal')
  }

  revealOnIntersect(elements) {
    elements.forEach((element) => {
      if (element.isIntersecting === true) {
        this.reveal(element.target);
      }
    });
  }

  init() {
    const { elements, observer } = this;

    if (elements.length === 0) {
      return;
    }

    console.log('listen');
    document.addEventListener('siteLoaded', () => {
      elements.forEach((element) => {
        if (observer) {
          observer.observe(element);
        } else {
          this.reveal(element);
        }
      });
    });
  }
}

export default Reveal;
