class Video {
  constructor() {
    this.videos = document.querySelectorAll('.js-video');
  }

  playVideo(video) {
    const iframe = video.querySelector('.js-video-iframe');
    iframe.setAttribute('src', iframe.getAttribute('data-src'));
    video.classList.add('is-open');
  }

  closeVideo(video) {
    const iframe = video.querySelector('.js-video-iframe');
    video.classList.remove('is-open');

    setTimeout(() => {
      iframe.setAttribute('src', '');
    }, 300);
  }

  init() {
    const { videos } = this;

    videos.forEach((video) => {
      const play = video.querySelector('.js-video-play');
      const close = video.querySelector('.js-video-close');

      const onEsc = (e) => {
        if (e.key === 'Escape') {
          this.closeVideo(video);
        }
      };

      play.addEventListener('click', (e) => {
        e.preventDefault();

        this.playVideo(video);

        document.addEventListener('keyup', onEsc);
      });

      close.addEventListener('click', (e) => {
        e.preventDefault();

        this.closeVideo(video);

        document.removeEventListener('keyup', onEsc);
      });
    });
  }
}

export default Video;
